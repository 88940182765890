<template>
  <div>
    <van-button class="button" plain type="info" @click="showPopupDialog(-1)" square>添加</van-button>

    <van-collapse v-model="activeName" accordion>
      <div class="item-safe-area" v-for="(item, index) in contracts" :key="index + item.cellphone">
      <!-- <van-cell :title="item.name" is-link :value="item.cellphone" @click="showPopupDialog(index)"/> -->
        <van-collapse-item :title="item.name" :name="index" :value="item.cellphone" style="width:100%">
          <van-cell-group>
            <van-button type="default" @click="deleteContract(index)" style="width:50%">删除</van-button>
            <van-button type="default" @click="showPopupDialog(index)" style="width:50%">编辑</van-button>
          </van-cell-group>
        </van-collapse-item>
      </div>
    </van-collapse>

    <van-dialog v-model="showPopup" title="通讯录/白名单" show-cancel-button
                @confirm="saveContract">
      <van-cell-group>
        <van-field v-model="modifyName" label="姓名" placeholder="请输入姓名" clearable/>
        <van-field v-model="modifyCellphone" label="号码" placeholder="请输入号码" clearable/>
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import {Field, Toast, Collapse, CollapseItem} from "vant";
import {post} from "@/router/httpUtils";
import {
  app_NetworkError,
  app_State_1800,
  app_State_1801, app_State_1802, OrderSet_SendFailure,
  OrderSet_SendSuccess,
  State_0,
  State_1800,
  State_1801, State_1802, State_2200
} from "@/constants/contants";
import {Contacts} from "@/constants/CmdCode";

Vue.use(Field)
Vue.use(Collapse);
Vue.use(CollapseItem);

export default {
  name: "DeviceContacts",
  data() {
    return {
      showPopup: false,
      contracts: [],
      modifyName: '',
      modifyCellphone: '',
      editIndex: -1,
      activeName: '1',
    }
  },
  mounted() {
    console.log(this.$route)
    if (!this.$route.params.CmdValue) return

    this.contracts = this.$route.params.CmdValue.split(',').map(v => {
      const array = v.split(':')
      return {
        name: array[0],
        cellphone: array[1]
      }
    })
  },
  methods: {
    showPopupDialog(index) {
      if (index !== -1) {
        const item = this.contracts[index]
        this.editIndex = index
        this.modifyName = item.name
        this.modifyCellphone = item.cellphone
      } else {
        this.editIndex = -1
        this.modifyName = ''
        this.modifyCellphone = ''
      }
      this.showPopup = true
    },
    saveContract() {

      if (this.editIndex === -1) {
        this.contracts.push({
          name: this.modifyName,
          cellphone: this.modifyCellphone
        })
      } else {
       this.contracts[this.editIndex] = {
         name: this.modifyName,
         cellphone: this.modifyCellphone
       }
      }

      this.sendCommand()
    },
    deleteContract(deleteIndex) {
      this.contracts.splice(deleteIndex)
      this.sendCommand()
    },
    sendCommand() {
      Toast.loading({
        message: '指令发送中...'
      })
      const params = this.contracts.map(c => {
        return c.name + ':' + c.cellphone
      }).join(',')
      console.log(params)
      post('/api/Command/SendCommand', {
        "CmdCode": Contacts,
        "DeviceId": localStorage.DeviceId,
        "DeviceModel": localStorage.TypeValue,
        "Params": params,
        "Token": localStorage.Token,
        "UserId": -1
      })
          .then(res => {
            console.log(res)
            const state = res.State
            if (res.Content != "NetworkError") {
              if (state == State_0) {
                Toast.success({
                  message: OrderSet_SendSuccess,
                  duration: 1000
                })
              } else if (state == State_1800) {
                Toast.fail({
                  message: app_State_1800,
                  duration: 3000
                })
              } else if (state == State_1801) {
                Toast.fail({
                  message: app_State_1801,
                  duration: 3000
                })
              } else if (state == State_1802) {
                Toast.fail({
                  message: app_State_1802,
                  duration: 3000
                })
              } else if (state == State_2200) {
                Toast.fail({
                  message: '请先到孝心环设置界面设置主控号码',
                  duration: 3000
                })
              } else {
                Toast.fail({
                  message: OrderSet_SendFailure,
                  duration: 3000
                })
              }
            } else {
              Toast.fail({
                // eslint-disable-next-line no-undef
                message: app_NetworkError,
                duration: 3000
              })
            }
          })
          // .catch(err => {
            // Toast.fail({
            //   message: err
            // })
          // })
    }
  }
}
</script>

<style scoped>
.button {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.item-safe-area {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid gray;
}

</style>